import * as R from 'ramda';
import * as Sentry from '@sentry/react';

// List of blacklist word that will be scrubbed from the logs
const piiPrivateBlacklist = [
  'secret',
  'secretKey',
  'token',
  'document',
  'acceptedTerms',
  'identityAuthToken',
  'acceptedDeclaration',
  'firstName',
  'lastName',
  'name',
  'email',
  'givenNames',
  'familyNames',
  'dateOfBirth',
  'gender',
  'evidences',
  'dateOfExpiry',
  'phoneNumber',
  'document.number',
  'X-Forwarded-For',
  'x-civic-feedback-token',
  'sourceIp',
];

// Specific errors to ignore
export const ignoredErrors = [
  // Don't log when user rejects the connection request in their wallet
  /User rejected the request/,
  /user rejected transaction/,
  /call revert exception/,
  'No error message',
  'Uncompressed Asset',
  'No response from remote app for REQUEST_SIGNED_PROOF', // When user cancels the login
  // From facetec
  'play() can only be initiated by a user gesture',
];

// Specific URLs to ignore
export const denyUrls = [
  // Chrome extension errors should not be logged
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
];

export function containsBlacklistWords(input: string | undefined): boolean {
  if (R.isNil(input)) {
    return false;
  }
  return piiPrivateBlacklist.some((blacklistItem) => input?.toLowerCase()?.includes(blacklistItem));
}

export const beforeSend = (event: Sentry.Event): Sentry.Event => {
  const scrubbedEvent = event;

  // Scrub the relevant data fields
  if (scrubbedEvent.user) {
    // Don't send user's email address
    delete scrubbedEvent.user.email;
  }
  return scrubbedEvent;
};

export const beforeBreadcrumb = (breadcrumb: Sentry.Breadcrumb): Sentry.Breadcrumb | null => {
  const scrubbedCrumb = breadcrumb;
  // Check if the message contains any blacklisted words
  if (containsBlacklistWords(scrubbedCrumb.message)) {
    // Exclude the whole message if any blacklisted words are found
    scrubbedCrumb.message = '[Scrubbed blacklisted message]';
  }
  return scrubbedCrumb;
};

export const formatError = (error: Record<string, unknown>, excludeProps: Array<string>): Record<string, unknown> => {
  const formatted: Record<string, unknown> = {};
  Object.getOwnPropertyNames(error).forEach((propName) => {
    if (!R.includes(propName, excludeProps)) {
      formatted[propName] = error[propName];
    }
  });
  return formatted;
};
