import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GatewayStatus } from '@civic/ethereum-gateway-react';
import { useChain } from '@civic/multichain-connect-react-core';
import StatusPanelContent from './StatusPanelContent';
import { useRedirect } from '../hooks/useRedirect';
import analytics from '../analytics';
import Log from '../logging';

export type TokenStatusPanelProps = {
  connected: boolean;
  gatewayStatus?: GatewayStatus;
  requestGatewayToken?: () => void;
};
export default function TokenStatusPanel({
  connected,
  gatewayStatus = GatewayStatus.UNKNOWN,
  requestGatewayToken,
}: TokenStatusPanelProps): JSX.Element {
  const history = useHistory();
  const [status, setStatus] = useState<string>(GatewayStatus[GatewayStatus.CHECKING]);
  const googleAnalyticsEvent = `Token ${status} - Civic Pass`;
  const { selectedChain } = useChain();

  useRedirect({ condition: gatewayStatus === GatewayStatus.ACTIVE, code: GatewayStatus[gatewayStatus] });

  useEffect(() => {
    Log.debug('TokenStatusPanel useEffect', { gatewayStatus: gatewayStatus && GatewayStatus[gatewayStatus] });
    setStatus(GatewayStatus[gatewayStatus]);
  }, [gatewayStatus]);

  const handleButtonClick = () => {
    requestGatewayToken?.();
  };

  const handleLearnMore = () => {
    history.push(`/documentation${selectedChain ? `?chain=${selectedChain?.name}` : ``}`);
  };

  useEffect(() => {
    analytics.pageView(window.location.pathname);
  }, [googleAnalyticsEvent]);

  return (
    <>
      <div style={{ visibility: 'visible' }}>
        <StatusPanelContent
          connected={connected}
          status={status}
          handleButtonClick={handleButtonClick}
          handleLearnMore={handleLearnMore}
        />
      </div>
    </>
  );
}

TokenStatusPanel.defaultProps = {
  gatewayStatus: GatewayStatus.UNKNOWN,
  requestGatewayToken: () => Promise.resolve(),
};
