import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonStyle } from '../common/Components/Button';
import ButtonContainer from '../common/Components/ButtonContainer';
import MainContainer from '../common/MainContainer/MainContainer';
import { usePassProvider } from '../providers/PassProvider';

export default function DocumentationScreen(): JSX.Element {
  const { passConfig } = usePassProvider();
  const documentationUrl = 'http://docs.civic.com/';
  const { t } = useTranslation();
  const config = passConfig;

  const onOpenDocumentation = () => {
    window.open(documentationUrl, '_blank');
  };

  return (
    <MainContainer showBackButton>
      <div className="documentation-panel flex flex-col">
        <div className="block max-w-xl rounded-xl bg-white p-6 shadow-lg w-full border-slate-100 border">
          <div className="flex flex-col justify-center items-center">
            <img
              src="civic-pass.png"
              alt="Civic Pass Logo"
              className="full:w-9/12 w-1/2 items-center -mb-7 mt-4 mb-4"
            />
            <div className="flex flex-col items-start">
              <h3 className="documentation-description text-left text-lg font-bold">
                {t('screens.documentation.title1', { context: config.i18nContext })}{' '}
                <strong>{t('screens.documentation.title2', { context: config.i18nContext })}</strong>{' '}
                {t('screens.documentation.title3', { context: config.i18nContext })}
              </h3>
              <p className="documentation-description text-left mt-2">
                {t('screens.documentation.titleItemUniqueness', { context: config.i18nContext })}
              </p>
              <p className="documentation-description text-left">
                {t('screens.documentation.titleItemLiveness', { context: config.i18nContext })}
              </p>
              <p className="documentation-description text-left">
                {t('screens.documentation.titleItemIddocuments', { context: config.i18nContext })}
              </p>
              <p className="documentation-description text-left">
                {t('screens.documentation.titleItemLocation', { context: config.i18nContext })}
              </p>
              <p className="documentation-description text-left my-5">
                <strong>{t('screens.documentation.description1', { context: config.i18nContext })}</strong>{' '}
                {t('screens.documentation.description2', { context: config.i18nContext })}
              </p>
            </div>
            <ButtonContainer>
              <Button style={ButtonStyle.INVERTED} disabled={false} extraClasses="mb-2" onClick={onOpenDocumentation}>
                <span>{t('screens.documentation.buttonLabel', { context: config.i18nContext })}</span>
              </Button>
            </ButtonContainer>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}
