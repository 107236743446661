import React, { ReactChild } from 'react';

interface ChildProps {
  children: ReactChild | ReactChild[];
  align?: string;
  bottomMargin?: string;
}

export default function ButtonContainer({ children, align, bottomMargin }: ChildProps): JSX.Element {
  const alignStyles = align || 'items-center justify-center';
  const mb = bottomMargin || 'mb-5';
  return <div className={`flex-shrink-0 flex-grow-0 flex flex-col ${mb} ${alignStyles}`}>{children}</div>;
}

ButtonContainer.defaultProps = {
  align: '',
  bottomMargin: '',
};
