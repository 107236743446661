import React from 'react';
import { useGateway } from '@civic/solana-gateway-react';
import TokenStatusPanel from './TokenStatusPanel';

export default function SolanaTokenStatusPanel({ connected }: { connected: boolean }): JSX.Element {
  const { gatewayStatus, requestGatewayToken } = useGateway();

  return (
    <>
      <TokenStatusPanel connected={connected} gatewayStatus={gatewayStatus} requestGatewayToken={requestGatewayToken} />
    </>
  );
}

SolanaTokenStatusPanel.defaultProps = {};
