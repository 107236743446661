import * as Sentry from '@sentry/react';
import { Logger } from './logger';

export default class SentryLogger implements Logger {
  private logConsole: Console = console;

  private isProd = (process.env.REACT_APP_STAGE || 'unknown').toLowerCase() === 'prod';

  private isPreprod = (process.env.REACT_APP_STAGE || 'unknown').toLowerCase() === 'preprod';

  private isE2e = (process.env.REACT_APP_STAGE || 'unknown').toLowerCase() === 'e2e';

  private skipRemoteLogging(): boolean {
    const isLoggableStage = this.isProd || this.isPreprod || this.isE2e;
    const isSentryConfigured = Sentry.getCurrentHub().getClient() !== null;
    return !isLoggableStage || !isSentryConfigured;
  }

  debug(message: string, ...optionalParams: unknown[]): void {
    if (this.isPreprod) {
      this.logConsole.log(message, optionalParams);
    }
  }

  error(message: string, error: Error | null, ...optionalParams: unknown[]): void {
    if (this.isPreprod || this.isE2e) {
      this.logConsole.error(`${message}: ${error}`, optionalParams);
    }

    if (this.skipRemoteLogging()) {
      return;
    }
    if (error) {
      // Build and set the context to contain all the errors, their nested children and the message
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(message);
    }
  }
}
