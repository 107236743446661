import {
  mainnet,
  sepolia,
  polygon,
  arbitrum,
  arbitrumSepolia,
  fantom,
  fantomTestnet,
  polygonZkEvm,
  polygonZkEvmTestnet,
  optimism,
  optimismSepolia,
  base,
  avalanche,
  avalancheFuji,
} from 'wagmi/chains';
import { Chain as EthereumChain } from '@civic/multichain-connect-react-rainbowkit-wallet-adapter';
import { solanaDevnetEndpoint, solanaMainnetEndpoint } from '../common/config/solana';

export const xdcMainnet = {
  id: 50,
  name: 'XDC',
  network: 'xdcMainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'XDC',
    symbol: 'XDC',
  },
  rpcUrls: {
    default: {
      http: ['https://erpc.xinfin.network'],
    },
    public: {
      http: ['https://erpc.xinfin.network'],
    },
  },
  blockExplorers: {
    default: { name: 'BlocksScan', url: 'https://explorer.xinfin.network' },
    etherscan: { name: 'BlocksScan', url: 'https://explorer.xinfin.network' },
  },
  testnet: false,
};

export const xdcApothem = {
  id: 51,
  name: 'XDC Apothem',
  network: 'xdcApothem',
  nativeCurrency: {
    decimals: 18,
    name: 'TXDC',
    symbol: 'TXDC',
  },
  rpcUrls: {
    default: {
      http: ['https://erpc.apothem.network'],
    },
    public: {
      http: ['https://erpc.apothem.network'],
    },
  },
  blockExplorers: {
    default: { name: 'BlocksScan', url: 'https://explorer.apothem.network' },
    etherscan: { name: 'BlocksScan', url: 'https://explorer.apothem.network' },
  },
  testnet: true,
};

export const baseSepolia = {
  id: 84532,
  name: 'Base Sepolia',
  network: 'baseSepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['https://sepolia.base.org'],
    },
    public: {
      http: ['https://sepolia.base.org'],
    },
  },
  blockExplorers: {
    default: { name: 'Blockscout', url: 'https://base-sepolia.blockscout.com/' },
    etherscan: { name: 'Blockscout', url: 'https://base-sepolia.blockscout.com/' },
  },
  testnet: true,
};

// Viem v1 does not include amoy
// TODO UPDATE WAGMI & VIEM
export const polygonAmoy = {
  id: 80_002,
  name: 'Polygon Amoy',
  network: 'polygonAmoy',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
    public: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
  },
  blockExplorers: {
    default: {
      name: 'OK LINK',
      url: 'https://www.oklink.com/amoy',
    },
  },
  testnet: true,
};

export const chains = {
  ethereum: mainnet,
  sepolia,
  polygon,
  polygonAmoy,
  xdcMainnet,
  xdcmainnet: xdcMainnet,
  xdcApothem,
  'arbitrum one': arbitrum,
  arbitrumSepolia,
  fantom,
  fantomTestnet,
  polygonZkEvm,
  polygonZkEvmTestnet,
  optimism,
  optimismSepolia,
  base,
  baseSepolia,
  avalanche,
  avalancheFuji,
};

export const clientChains: EthereumChain[] = [
  chains.polygon,
  chains.ethereum,
  chains['arbitrum one'],
  chains.optimism,
  chains.avalanche,
  chains.base,
  chains.fantom,
  chains.polygonZkEvm,
  chains.xdcMainnet,
];

export const clientTestChains: EthereumChain[] = [
  chains.polygonAmoy,
  chains.sepolia,
  chains.arbitrumSepolia,
  chains.optimismSepolia,
  chains.avalancheFuji,
  chains.baseSepolia,
  chains.xdcApothem,
  chains.fantomTestnet,
  chains.polygonZkEvmTestnet,
];

export const defaultSolanaChains = [{ name: 'Solana', rpcEndpoint: solanaMainnetEndpoint }];
export const defaultSolanaTestChains = [
  {
    name: 'Solana Devnet',
    rpcEndpoint: solanaDevnetEndpoint,
  },
];
