import * as R from 'ramda';
import * as Sentry from '@sentry/react';
import * as SentryUtils from './Sentry.utils';
import Log from '.';

import LocalLogger from './LocalLogger';
import SentryLogger from './SentryLogger';

const initializeLogger = (): void => {
  const dsn = process.env.REACT_APP_SENTRY_DSN || '';
  const stage = (process.env.REACT_APP_STAGE || 'unknown').toLowerCase();

  if (R.contains(stage, ['prod', 'preprod', 'e2e'])) {
    Sentry.init({
      dsn,
      environment: stage,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 0.001,
      ignoreErrors: SentryUtils.ignoredErrors,
      denyUrls: SentryUtils.denyUrls,
      beforeSend: SentryUtils.beforeSend,
      beforeBreadcrumb: SentryUtils.beforeBreadcrumb,
    });
    Log.setLogger(new SentryLogger());
  } else if (R.contains(stage, ['dev'])) {
    Log.setLogger(new LocalLogger());
  }
};

export default initializeLogger;
