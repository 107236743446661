import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { MultichainConnectButton, useChain } from '@civic/multichain-connect-react-core';
import { usePolypassFlowProvider } from '@civic/polypass-react';
import { isUniquenessPortal } from '../utils/util';
import SendToIdWallet from '../assets/img/SendToIdWallet';
import { getIDExportConfig } from '../config';
import PassSelector from '../Components/PassSelector';
import analytics from '../analytics';

interface StatusPanelContentProps {
  status: string;
  handleButtonClick: () => void;
  handleLearnMore: () => void;
  connected: boolean;
}

type Option = { label: string; img?: string };
const SendToIdWalletChoices = ({
  buttonRef,
  onOptionSelected,
}: {
  buttonRef: React.RefObject<HTMLButtonElement>;
  onOptionSelected: (_option: string) => void;
}) => {
  const { t } = useTranslation();
  const options: Option[] = [{ label: t('screens.sendToIdWallet.polygonId') }];
  const { launch } = usePolypassFlowProvider();
  const handleOptionClick = (option: Option) => {
    analytics.event('option', 'select', 'wallet', option.label);
    if (option === options[0]) {
      launch();
    }
  };
  const overlayStyle = buttonRef.current
    ? {
        top: `${buttonRef.current.offsetTop + buttonRef.current.offsetHeight}px`,
        left: `${buttonRef.current.offsetLeft}px`,
      }
    : {};
  return (
    <div className="absolute left-0 mt-2" style={overlayStyle}>
      <div className="bg-white rounded-lg shadow-md shadow-primary/50 border-primary overflow-hidden">
        <ul>
          {options.map((option) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              key={option.label}
              className="cursor-pointer py-2 px-4 hover:bg-[#FF6B4E] hover:bg-opacity-10 flex items-center border-b border-gray-200 text-primary"
              onKeyPress={() => handleOptionClick(option)}
              onClick={() => {
                onOptionSelected(option.label);
                handleOptionClick(option);
              }}
            >
              <span className="flex-grow">{option.label}</span>
              {option.img && <img src="/icons/chainIcons/polygon.svg" alt="" className="ml-2" />}
            </li>
          ))}
          <li key="comingSoon" className="py-2 px-4 flex items-center text-gray-400 cursor-not-allowed">
            <span className="flex-grow">{t('screens.sendToIdWallet.comingSoon')}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

const LaunchIDWallet = ({ status }: { status: string }) => {
  const { selectedChain } = useChain();
  const [showOverlay, setShowOverlay] = React.useState(false);
  const { t } = useTranslation();

  const idExportOptions = useMemo(
    () => (selectedChain?.name ? getIDExportConfig(selectedChain?.name) : []),
    [selectedChain]
  );
  const buttonRef = useRef(null);
  const onOptionSelected = () => {
    setShowOverlay(false);
  };
  return (
    <>
      {idExportOptions && idExportOptions.length > 0 && status === 'ACTIVE' ? (
        <>
          <div className="flex items-center justify-center">
            <button
              ref={buttonRef}
              type="button"
              className="transition duration-200 ease-in-out bg-white text-primary font-bold py-2 px-4 rounded hover:underline  flex items-center"
              onClick={() => setShowOverlay(true)}
            >
              <span>{t('screens.idExportOptions.launcherButton')}</span>
              <span className="ml-1">
                <SendToIdWallet />
              </span>
            </button>
            {showOverlay && <SendToIdWalletChoices onOptionSelected={onOptionSelected} buttonRef={buttonRef} />}
          </div>
        </>
      ) : null}
    </>
  );
};

const StyledPanel = tw.div`flex flex-col items-center full:flex-row full:-space-x-40 justify-center`;
const CardContainer = tw.div`block max-w-xl rounded-xl bg-white p-6 shadow-lg w-full border-[#f1f5f9] border`;
const CardContent = tw.div`flex flex-col justify-center items-center full:items-center`;
const PassSelectorContainer = tw.div`px-4 full:px-7 flex flex-col items-center mb-4`;
const LinkButton = tw.button`text-base leading-5 font-bold disabled:text-textInactive text-textTertiary hover:text-textBase`;
const StatusButton = tw.button`relative border border-white`;

interface ImageContainerProps {
  grayscale?: boolean;
}

const CardImage: React.FC<ImageContainerProps> = ({ grayscale = false }) => (
  <div className={`full:w-9/12 w-1/2 items-center -mb-7 ${grayscale ? 'grayscale' : ''}`}>
    <img src="card.png" alt="active" />
  </div>
);

export default function StatusPanelContent({
  status,
  handleButtonClick,
  handleLearnMore,
  connected,
}: StatusPanelContentProps): JSX.Element {
  const { t } = useTranslation();
  const { selectedChain } = useChain();

  const handleLearnMoreClick = () => {
    analytics.event('button', 'click', 'learn more', t('screens.tokenStatus.supportLink', { context: status }));
    handleLearnMore();
  };

  const handleConnectClick = () => {
    analytics.event('button', 'click', 'connect', t('screens.tokenStatus.buttonLabel', { context: status }));
    handleButtonClick();
  };

  return (
    <StyledPanel>
      <CardContainer>
        <CardContent>
          <PassSelectorContainer>
            <PassSelector />
          </PassSelectorContainer>

          {!connected && (
            <div className="flex flex-col items-center">
              <CardImage />
              <div
                className={`mt-10 multi-connect-button ${connected ? selectedChain?.type : ''}`}
                data-testid="multiChainConnectButton"
              >
                <MultichainConnectButton />
              </div>
            </div>
          )}

          {connected && (
            <>
              {/* When refresh is needed, show additional copy */}
              {status === 'REFRESH_TOKEN_REQUIRED' && (
                <div className="flex flex-col items-center">
                  <CardImage grayscale />
                  <span className="mt-9 text-sm items-center text-center mb-3 text-black">Your verification is:</span>
                  <StatusButton className={`status-button ${status} mb-3 text-black`} onClick={handleConnectClick}>
                    <span className={`dot ${status} mr-2 text-black`} />
                    <span>{t('screens.tokenStatus.buttonLabel', { context: status })}</span>
                  </StatusButton>

                  <LinkButton disabled={false} onClick={handleLearnMoreClick}>
                    <span>{t('screens.tokenStatus.supportLink', { context: status })}</span>
                  </LinkButton>
                </div>
              )}

              {/* For any other status show the status button */}
              {status !== 'REFRESH_TOKEN_REQUIRED' && (
                <div className="flex flex-col items-center">
                  <CardImage />
                  <StatusButton className={`status-button ${status} mt-9 mb-5`} onClick={handleConnectClick}>
                    <span className={`dot ${status} mr-2`} />
                    <span>{t('screens.tokenStatus.buttonLabel', { context: status })}</span>
                  </StatusButton>

                  {status !== 'ACTIVE' && (
                    <LinkButton disabled={false} onClick={handleLearnMoreClick}>
                      <span>{t('screens.tokenStatus.supportLink', { context: status })}</span>
                    </LinkButton>
                  )}
                </div>
              )}
            </>
          )}
        </CardContent>

        <LaunchIDWallet status={status} />

        {/* <>{!R.isEmpty(description) && <p className="mb-5">{description}</p>}</> */}
        <p className="mt-4 mb-5">{isUniquenessPortal() ? t('screens.tokenStatus.uniquenessBlurb') : ''}</p>
      </CardContainer>
    </StyledPanel>
  );
}

CardImage.defaultProps = {
  grayscale: false,
};
