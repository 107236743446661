import React, { useEffect, useMemo, useState } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { MultichainWalletProvider } from '@civic/multichain-connect-react-core';
import { Chain as SolanaChain, SolanaWalletAdapterConfig } from '@civic/multichain-connect-react-solana-wallet-adapter';
import { Chain as EthereumChain, RainbowkitConfig } from '@civic/multichain-connect-react-rainbowkit-wallet-adapter';
import { publicProvider } from 'wagmi/providers/public';
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  WalletConnectWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  clientChains as defaultEvmChains,
  clientTestChains as defaultEvmTestChains,
  defaultSolanaChains,
  defaultSolanaTestChains,
} from './providers/types';
import TokenStatusScreen from './screens/TokenStatusScreen';
import DocumentationScreen from './screens/DocumentationScreen';
import WalletConnectionScreen from './screens/WalletConnectionScreen';
import { PassProvider } from './providers/PassProvider';
import { myCustomTheme } from './common/css/rainbowTheme';
import { getRedirectChainMapping } from './common/utils/util';
import { useQueryParams } from './common/hooks/useQueryParams';

function PassProviderWrapper(): JSX.Element {
  return (
    <PassProvider>
      <BrowserRouter>
        <Route path="/" exact component={WalletConnectionScreen} />
        <Route path="/status" exact component={TokenStatusScreen} />
        <Route path="/documentation" exact component={DocumentationScreen} />
      </BrowserRouter>
    </PassProvider>
  );
}

PassProviderWrapper.defaultProps = {
  chainFromHash: undefined,
};

// The keys to these mappings are regex to allow for flexibility in matching logic
const redirectChainMappings = {
  '.*gitcoin\\.co.*': { evm: true, solana: false },
};

export default function App(): JSX.Element {
  const [evmChains, setEvmChains] = useState<{
    chains: EthereumChain[];
    testChains: EthereumChain[];
  }>({ chains: defaultEvmChains, testChains: defaultEvmTestChains });
  const [solanaChains, setSolanaChains] = useState<{
    chains: SolanaChain[];
    testChains: SolanaChain[];
  }>({ chains: defaultSolanaChains, testChains: defaultSolanaTestChains });
  const { chainsParam, redirectParam } = useQueryParams();

  /**
   *
   * If a redirect param was given, check if it matches a redirect chain mapping to limit the chains displayed
   *
   * If the url contains a "chain" query Parameter, filter the chains to only show the selected chain(s). The ordering
   * in the query parameter is preserved
   *
   * If the url contains a "scope" query Parameter, filter the passes to only show the selected scope passes. The ordering
   * in the query parameter is preserved
   *
   * If no URL parameters are given, use the default chains
   */
  useEffect(() => {
    if (redirectParam) {
      const mapping = getRedirectChainMapping(redirectParam, redirectChainMappings);
      if (mapping) {
        const { evm, solana } = mapping;
        // If we are redirecting from a site and want to specify certain chains based on that site, we can do that here
        if (evm) {
          setEvmChains({
            chains: defaultEvmChains,
            testChains: defaultEvmTestChains,
          });
        } else {
          setEvmChains({ chains: [], testChains: [] });
        }
        if (solana) {
          setSolanaChains({
            chains: defaultSolanaChains,
            testChains: defaultSolanaTestChains,
          });
        } else {
          setSolanaChains({ chains: [], testChains: [] });
        }
        return;
      }
    }

    if (!chainsParam || chainsParam.length === 0) {
      setEvmChains({
        chains: defaultEvmChains,
        testChains: defaultEvmTestChains,
      });
      setSolanaChains({
        chains: defaultSolanaChains,
        testChains: defaultSolanaTestChains,
      });
      return;
    }

    const orderedSelectedChains = <T extends EthereumChain | SolanaChain>(chainsList: T[]): T[] =>
      chainsParam
        .map((cParam) => chainsList.find((dChain: T) => dChain.name.toLowerCase() === cParam.toLowerCase()))
        .filter((item): item is T => item !== undefined);

    const selectedEvmChains = orderedSelectedChains<EthereumChain>([...defaultEvmChains, ...defaultEvmTestChains]);
    const selectedSolanaChains = orderedSelectedChains<SolanaChain>([
      ...defaultSolanaChains,
      ...defaultSolanaTestChains,
    ]);

    const evm = selectedEvmChains.filter((c) => defaultEvmChains.includes(c));
    const solana = selectedSolanaChains.filter((c) => defaultSolanaChains.includes(c));
    const evmTest = selectedEvmChains.filter((c) => defaultEvmTestChains.includes(c));
    const solanaTest = selectedSolanaChains.filter((c) => defaultSolanaTestChains.includes(c));
    const allchains = [...evm, ...solana, ...evmTest, ...solanaTest];

    if (allchains.length === 0) {
      setEvmChains({
        chains: defaultEvmChains,
        testChains: defaultEvmTestChains,
      });
      setSolanaChains({
        chains: defaultSolanaChains,
        testChains: defaultSolanaTestChains,
      });
      return;
    }

    setEvmChains({
      chains: evm,
      testChains: evmTest,
    });
    setSolanaChains({
      chains: solana,
      testChains: solanaTest,
    });
  }, [chainsParam.length]);

  const solanaAdapters = useMemo(() => {
    return [
      new PhantomWalletAdapter(), // re-adding this as Phantom is not showing on mobile browsers
      new SolflareWalletAdapter(), // TODO CPASS-1585, don't include Solflare for Android only
      new LedgerWalletAdapter(),
      new WalletConnectWalletAdapter({
        options: { projectId: process.env.REACT_APP_PORTAL_WALLET_CONNECT_PROJECT_ID || '' },
        network: solanaChains.chains.length > 0 ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet,
      }),
      new TorusWalletAdapter(),
    ];
  }, []);

  return (
    <MultichainWalletProvider>
      <RainbowkitConfig
        chains={evmChains.chains}
        testnetChains={evmChains.testChains}
        theme={myCustomTheme}
        providers={[publicProvider()]}
        options={{
          appName: 'Civic Pass Portal',
          // Rainbowkit relies on WalletConnect which now needs to obtain a projectId from WalletConnect Cloud.
          walletConnectProjectId: process.env.REACT_APP_PORTAL_WALLET_CONNECT_PROJECT_ID || '',
        }}
      >
        <SolanaWalletAdapterConfig
          chains={solanaChains.chains}
          testnetChains={solanaChains.testChains}
          adapters={solanaAdapters}
        >
          <PassProvider>
            <Route path="/" exact component={WalletConnectionScreen} />
            <Route path="/status" exact component={TokenStatusScreen} />
            <Route path="/documentation" exact component={DocumentationScreen} />
          </PassProvider>

          <Toaster position="bottom-left" reverseOrder={false} />
        </SolanaWalletAdapterConfig>
      </RainbowkitConfig>
    </MultichainWalletProvider>
  );
}
