import React, { ReactChild } from 'react';

interface ButtonProps {
  children: ReactChild | ReactChild[];
  style?: ButtonStyle;
  overrideClasses?: string;
  extraClasses?: string;
  loading?: boolean;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  dataTestid?: string;
}

export enum ButtonStyle {
  DEFAULT = 'default',
  INVERTED = 'inverted',
}

export const defaultStyleClasses = 'buttonDefault';

export const invertedStyleClasses = 'bg-transparent disabled:text-textInactive textLink';

export default function Button({
  children,
  style,
  overrideClasses = '',
  extraClasses = '',
  loading = false,
  disabled,
  onClick,
  dataTestid = '',
}: ButtonProps): JSX.Element {
  const baseClasses =
    'civic-button relative w-auto wide:w-full flex items-center justify-center font-bold py-3 px-10 rounded-lg transition';
  const uxClasses = 'disabled:cursor-auto';

  const overrideStyleClasses = overrideClasses;

  let themeClasses;
  let buttonLoadingThemeClasses;
  switch (style) {
    case ButtonStyle.DEFAULT:
    default:
      themeClasses = defaultStyleClasses;
      buttonLoadingThemeClasses = 'loading-spinner';
      break;
    case ButtonStyle.INVERTED:
      themeClasses = invertedStyleClasses;
      buttonLoadingThemeClasses = 'loading-spinner';
      break;
  }
  const childrenVisibleClasses = loading ? 'invisible' : 'visible';

  return (
    <button
      type="button"
      data-testid={`${dataTestid}`}
      className={`${baseClasses} ${uxClasses} ${themeClasses} ${overrideStyleClasses} ${extraClasses}`}
      disabled={disabled}
      onClick={onClick}
    >
      {loading && <div className={buttonLoadingThemeClasses} />}
      <div className={childrenVisibleClasses}>{children}</div>
    </button>
  );
}

Button.defaultProps = {
  style: ButtonStyle.DEFAULT,
  overrideClasses: '',
  extraClasses: '',
  loading: false,
  dataTestid: '',
};
