import { Cluster, clusterApiUrl } from '@solana/web3.js';

export type ExtendedCluster = Cluster | 'civicnet' | 'localnet';

export const solanaMainnetEndpoint =
  process.env.REACT_APP_SOLANA_MAINNET_RPC && `${process.env.REACT_APP_SOLANA_MAINNET_RPC}`.trim() !== ''
    ? process.env.REACT_APP_SOLANA_MAINNET_RPC
    : clusterApiUrl('mainnet-beta');

export const solanaDevnetEndpoint =
  process.env.REACT_APP_SOLANA_DEVNET_RPC && `${process.env.REACT_APP_SOLANA_DEVNET_RPC}`.trim() !== ''
    ? process.env.REACT_APP_SOLANA_DEVNET_RPC
    : clusterApiUrl('devnet');

export const clusterEndpoint = (stage: string): string => {
  switch (stage) {
    case 'prod':
      return solanaMainnetEndpoint;
    default: {
      return solanaDevnetEndpoint;
    }
  }
};

export const cluster = (stage: string): Cluster => {
  switch (stage) {
    case 'prod':
      return 'mainnet-beta';
    default: {
      return 'devnet';
    }
  }
};
