import * as R from 'ramda';

export type PassType = 'ignite' | 'vaal' | 'blueNile' | 'uniqueness' | 'ageCheck13' | 'faceAgeCheck13';

export enum IdExportType {
  POLYGON = 'POLYGON',
}
export type IdExportDetail = {
  name: string;
  type: IdExportType;
  logo?: string;
};

type PortalConfigs = {
  polypassIframeUrl: string;
  gatewayStage: string;
  network: string;
  passConfigs: Record<string, PassConfig>;
  idExportConfig: Record<string, IdExportDetail[] | null>;
};

export type ChainNetworkConfig = {
  useClientSends: boolean;
};

export type PassConfig = {
  gatekeeperNetworkPublicKey: string; // We have to define the networks supported by the portal, so they're defined here
  useClientSends: boolean;
  chainConfig?: Record<number, ChainNetworkConfig>;
};

const supportedTestDomains = ['demo', 'localhost', 'dev', 'preprod'];

// create a function that returns if the domain is a test domain
export const isDemoDomain = (domain: string = window.location.hostname): boolean =>
  supportedTestDomains.some((testDomain) => domain.includes(testDomain));

const devPassConfig = {
  ignite: {
    gatekeeperNetworkPublicKey: 'tigoYhp9SpCDoCQmXGj2im5xa3mnjR1zuXrpCJ5ZRmi',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  vaal: {
    gatekeeperNetworkPublicKey: 'tvaaHL9BSgZGLRAqUrx1Fzs2Uneb6BWGdnYuqrFoXm3',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
    },
  },
  blueNile: {
    // Non-prod environments still use 'test BlueNile' (tbni...), just renamed to "Civic ID".
    // On prod we have the separate 'cid...' network, separate from BlueNile which is deprecated.
    gatekeeperNetworkPublicKey: 'tbniJdS9j7BWhUoJesjpqutC54AYr96cn2No7dJcqce',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  uniqueness: {
    gatekeeperNetworkPublicKey: 'tunQheuPpHhjjsbrUDp4rikqYez9UXv4SXLRHf9Kzsv',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  ageCheck13: {
    gatekeeperNetworkPublicKey: 'ta136ftcpd8RyTYu3FGBjstD7EMXGBWrcwotdScrAKu',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  faceAgeCheck13: {
    gatekeeperNetworkPublicKey: 'tf1394kihohAL86BoDZkZtiRpbAKPHzCRhgjsZZn4LY',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
};

const preprodPassConfig = {
  ignite: {
    gatekeeperNetworkPublicKey: 'tigoYhp9SpCDoCQmXGj2im5xa3mnjR1zuXrpCJ5ZRmi',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  vaal: {
    gatekeeperNetworkPublicKey: 'tvaaHL9BSgZGLRAqUrx1Fzs2Uneb6BWGdnYuqrFoXm3',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  blueNile: {
    // Non-prod environments still use 'test BlueNile' (tbni...), just renamed to "Civic ID".
    // On prod we have the separate 'cid...' network, separate from BlueNile which is deprecated.
    gatekeeperNetworkPublicKey: 'tbniJdS9j7BWhUoJesjpqutC54AYr96cn2No7dJcqce',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  uniqueness: {
    gatekeeperNetworkPublicKey: 'tunQheuPpHhjjsbrUDp4rikqYez9UXv4SXLRHf9Kzsv',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  ageCheck13: {
    gatekeeperNetworkPublicKey: 'ta136ftcpd8RyTYu3FGBjstD7EMXGBWrcwotdScrAKu',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  faceAgeCheck13: {
    gatekeeperNetworkPublicKey: 'tf1394kihohAL86BoDZkZtiRpbAKPHzCRhgjsZZn4LY',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
};

const prodPassConfig = {
  ignite: {
    gatekeeperNetworkPublicKey: 'ignREusXmGrscGNUesoU9mxfds9AiYTezUKex2PsZV6',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  vaal: {
    gatekeeperNetworkPublicKey: 'vaa1QRNEBb1G2XjPohqGWnPsvxWnwwXF67pdjrhDSwM',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  blueNile: {
    // Non-prod environments still use 'test BlueNile' (tbni...), just renamed to "Civic ID".
    // On prod we have the separate 'cid...' network as below, separate from BlueNile which is deprecated.
    gatekeeperNetworkPublicKey: 'cidNdd9GGhpgUJRTrto1A1ayN2PKAuaW7pg1rqj6bRD',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  uniqueness: {
    gatekeeperNetworkPublicKey: 'uniqobk8oGh4XBLMqM68K8M2zNu3CdYX7q5go7whQiv',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  // Age check passes hidden on prod for now, CPASS-1170
  /*
  ageCheck13: {
    gatekeeperNetworkPublicKey: 'ag13vc1KaV5kMjp3Gb5yTq8cyZSnHPVkTHdNJ2fWXk1',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  faceAgeCheck13: {
    gatekeeperNetworkPublicKey: 'fa136GTTrXLPajmYiwtprHK3pAkQJJGYqspzW1hvxJ6',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
  */
};

const demoPassConfig = {
  civicDemoPass: {
    gatekeeperNetworkPublicKey: 'tgnuXXNMDLK8dy7Xm1TdeGyc95MDym4bvAQCwcW21Bf',
    useClientSends: true,
    chainConfig: {
      // Ethereum homestead
      1: {
        useClientSends: true,
      },
      solana: {
        useClientSends: true,
      },
    },
  },
};

const polygonIdExportConfig = {
  type: IdExportType.POLYGON,
  name: 'Polygon ID',
};
export const defaultIdExportConfig = {
  Polygon: [polygonIdExportConfig],
  'Polygon Mumbai': [polygonIdExportConfig],
  'Polygon Amoy': [polygonIdExportConfig],
};
export const defaultDevNetwork = process.env.DEFAULT_CHAIN_NETWORK || 'sepolia';
export const defaultProdNetwork = process.env.DEFAULT_CHAIN_NETWORK || 'sepolia';
export const DEFAULT_CHAIN_NETWORK = process.env.REACT_APP_STAGE === 'prod' ? defaultProdNetwork : defaultDevNetwork;

const mergePassConfig = (config: Record<string, PassConfig>) =>
  isDemoDomain() ? { ...config, ...demoPassConfig } : config;

const portalConfigs: Record<string, PortalConfigs> = {
  e2e: {
    polypassIframeUrl: 'https://polypass-dev.civic.com',
    gatewayStage: 'dev',
    network: defaultDevNetwork,
    passConfigs: mergePassConfig(devPassConfig),
    idExportConfig: defaultIdExportConfig,
  },
  dev: {
    polypassIframeUrl: 'https://polypass-dev.civic.com',
    gatewayStage: 'dev',
    network: defaultDevNetwork,
    passConfigs: mergePassConfig(devPassConfig),
    idExportConfig: defaultIdExportConfig,
  },
  preprod: {
    polypassIframeUrl: 'https://polypass-preprod.civic.com',
    gatewayStage: 'preprod',
    network: defaultDevNetwork,
    passConfigs: mergePassConfig(preprodPassConfig),
    idExportConfig: defaultIdExportConfig,
  },
  prod: {
    polypassIframeUrl: 'https://polypass.civic.com',
    gatewayStage: 'prod',
    network: defaultProdNetwork,
    passConfigs: mergePassConfig(prodPassConfig),
    idExportConfig: defaultIdExportConfig,
  },
};

export type PortalConfig = PortalConfigs &
  PassConfig & {
    i18nContext: PassType;
  };

export const defaultPassType = (process.env.REACT_APP_PASS_TYPE as PassType) || 'ignite';

export type ChainInfo = {
  id: number | string;
  name: string;
};

function getPortalConfig(passType = defaultPassType, chain: ChainInfo | undefined = undefined): PortalConfig {
  const stage = process.env.REACT_APP_STAGE || 'dev';
  const config = R.propOr<PortalConfigs, Record<string, PortalConfigs>, PortalConfigs>(
    portalConfigs.dev,
    stage,
    portalConfigs
  );
  const passConfig = config.passConfigs[passType];
  const chainConfig = passConfig.chainConfig && chain ? passConfig.chainConfig[chain.id] : undefined;
  const configClientSends = chainConfig !== undefined ? chainConfig.useClientSends : passConfig.useClientSends;

  // Check if the env var is set, if not, check the chain network config,
  // if not, default to the top level config

  const useClientSends = `${process.env.REACT_APP_CLIENT_SENDS_ENABLED}`.toLowerCase() === 'true' || configClientSends;

  return {
    ...config,
    network: chain?.name ?? 'unknown',
    gatekeeperNetworkPublicKey: config.passConfigs[passType].gatekeeperNetworkPublicKey,
    useClientSends,
    i18nContext: passType,
  };
}

export const getIDExportConfig = (chainNetwork: string): IdExportDetail[] | null => {
  const stage = process.env.REACT_APP_STAGE || 'dev';
  const config = R.propOr<PortalConfigs, Record<string, PortalConfigs>, PortalConfigs>(
    portalConfigs.dev,
    stage,
    portalConfigs
  );
  return config.idExportConfig[chainNetwork];
};
export { getPortalConfig };
