import React, { useContext, useMemo, useState } from 'react';
import { useChain } from '@civic/multichain-connect-react-core';
import { ChainInfo, PassType, PortalConfig, defaultPassType, getPortalConfig } from '../common/config';

export type PassProps = {
  setPass: React.Dispatch<React.SetStateAction<PassType>>;
  pass: PassType;
  passConfig: PortalConfig;
};

const PassContext = React.createContext<PassProps>({
  setPass: () => {},
  pass: defaultPassType,
  passConfig: getPortalConfig(defaultPassType),
});

export const PassProvider: React.FC<{ children: unknown }> = ({ children = null }) => {
  const [pass, setPass] = useState<PassType>(defaultPassType);

  const { selectedChain } = useChain();
  const config = useMemo(() => {
    const chainInfo =
      selectedChain?.type === 'ethereum'
        ? selectedChain
        : {
            ...selectedChain,
            id: 'solana',
          };
    return getPortalConfig(pass, chainInfo as ChainInfo);
  }, [selectedChain, pass]);

  return (
    <PassContext.Provider
      value={{
        setPass,
        pass,
        passConfig: config,
      }}
    >
      <>{children}</>
    </PassContext.Provider>
  );
};

export const usePassProvider = (): PassProps => useContext(PassContext);
