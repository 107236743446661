import { EthereumGatewayWallet } from '@civic/ethereum-gateway-react';
import { SupportedChains, useWallet, useChain } from '@civic/multichain-connect-react-core';
import { WalletContextState } from '@solana/wallet-adapter-react';
import { useState, useEffect } from 'react';
import { useWalletClient } from 'wagmi';
import { walletClientToSigner } from './useAdapters';

export interface MultiWalletContextState {
  connected: boolean;
  solanaWallet: WalletContextState | undefined;
  address: string | undefined;
  evmGatewayWallet: EthereumGatewayWallet | undefined;
}

export const useMultiWallet = (): MultiWalletContextState => {
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [evmGatewayWallet, setEvmGatewayWallet] = useState<EthereumGatewayWallet | undefined>();
  const { connected } = useWallet();
  const { selectedChain } = useChain();

  const { wallet: solanaWallet } = useWallet<SupportedChains.Solana, WalletContextState, never>();

  const { data: walletClient } = useWalletClient();

  useEffect(() => {
    if (solanaWallet?.publicKey) {
      setAddress(solanaWallet.publicKey.toBase58());
    }
  }, [solanaWallet]);

  useEffect(() => {
    // the wallet client chain is set asynchronously, so wait until
    // it's set before setting the wallet
    if (!walletClient?.chain) {
      setEvmGatewayWallet(undefined);
      return;
    }
    if (walletClient && walletClient?.account) {
      const signer = walletClientToSigner(walletClient);
      setEvmGatewayWallet({ address: walletClient.account?.address, signer });
    }
  }, [walletClient, selectedChain]);

  return {
    connected,
    address,
    solanaWallet: solanaWallet?.publicKey ? solanaWallet : undefined,
    evmGatewayWallet,
  };
};
