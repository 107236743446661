import React from 'react';
import { useGateway } from '@civic/ethereum-gateway-react';
import TokenStatusPanel from './TokenStatusPanel';

export default function EthereumTokenStatusPanel({ connected }: { connected: boolean }): JSX.Element {
  const { gatewayStatus, requestGatewayToken } = useGateway();

  return (
    <>
      <TokenStatusPanel connected={connected} gatewayStatus={gatewayStatus} requestGatewayToken={requestGatewayToken} />
    </>
  );
}

EthereumTokenStatusPanel.defaultProps = {};
