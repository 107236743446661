import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { MultichainConnectButton, useChain, useWallet } from '@civic/multichain-connect-react-core';
import MainContainer from '../common/MainContainer/MainContainer';
import { isUniquenessPortal } from '../common/utils/util';
import PassSelector from '../common/Components/PassSelector';
import analytics from '../common/analytics';

interface WalletConnectionScreenProps {
  status: string;
}
interface ContentProps {
  imageSize: number;
  paddingRight: number;
  marginRight: number;
}
const Card = styled.div<ContentProps>`
  ${tw`relative flex items-center wider:justify-center p-6 max-w-[700px] min-h-[220px] rounded-xl bg-white shadow-lg`}
  padding-right: ${({ paddingRight }) => paddingRight}px;
  margin-right: ${({ marginRight }) => marginRight}px;
`;

const CircleImage = styled.img<ContentProps>`
  ${tw`wider:hidden absolute -top-4 rounded-full object-cover overflow-visible`}
  right: ${({ imageSize }) => -(imageSize / 2)}px;
  height: ${({ imageSize }) => imageSize}px;
  width: ${({ imageSize }) => imageSize}px;
`;

const CardContent = styled.div`
  ${tw`z-10 break-words mr-0 full:mr-3`}
`;

export default function WalletConnectionScreen({ status }: WalletConnectionScreenProps): JSX.Element {
  const { connected, wallet } = useWallet();
  const { selectedChain } = useChain();
  const { t } = useTranslation();
  const history = useHistory();
  const { userAgent } = navigator;
  const isAndroid = /android/i.test(userAgent);
  const isiOS = /iphone|ipad|ipod/i.test(userAgent);
  const isDesktop = !isAndroid && !isiOS;

  const showConnectButton = isAndroid || isiOS || (isDesktop && !connected);
  const containerRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({ imageSize: 0, paddingRight: 0, marginRight: 0 });

  const handleLearnMore = () => {
    analytics.event('button', 'click', 'learn more', t('screens.tokenStatus.supportLink', { context: status }));
    history.push(`/documentation${selectedChain ? `?chain=${selectedChain?.name}` : ``}`);
  };

  useEffect(() => {
    // This is used to keep the image size at 110% of the card height, and centered on the right side of the card.
    // Half the image size is used as the right padding to ensure the content doesn't render under the card and allow for wrapping.
    const updatePadding = () => {
      const IMAGE_SCALING = 1.1;

      if (containerRef.current) {
        const height = containerRef.current.offsetHeight;

        // Return default settings if we're in mobile width (< 650px)
        if (window.innerWidth < 650) {
          setState({
            imageSize: height, // Show image 10% larger than the container
            paddingRight: 16, // Half of the image size to handle offsetting and wrapping
            marginRight: 0,
          });
          return;
        }

        const imageSize = height * IMAGE_SCALING;

        // Below 800 wide we're getting quite narrow, so create a bit more space by moving the image further right
        if (window.innerWidth < 800) {
          setState({
            imageSize: Math.floor(imageSize),
            paddingRight: Math.floor(imageSize * 0.5),
            marginRight: Math.floor(imageSize * 0.5 * 0.8), // decrease the margin by 20%
          });
          return;
        }

        // We need to add padding to the right of the card to ensure the content doesn't render under the card and allow for wrapping.
        const rightPadding = imageSize * 0.5;
        // Set right margin to push in the right side of the card to the left so it's at the middle of the image
        const rightMargin = rightPadding;
        setState({
          imageSize: Math.floor(imageSize),
          paddingRight: Math.floor(rightPadding),
          marginRight: Math.floor(rightMargin),
        });
      }
    };

    // Call the function once to set the initial padding
    updatePadding();

    // Update padding when the window resizes
    window.addEventListener('resize', updatePadding);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updatePadding);
    };
  }, []);

  useEffect(() => {
    if (/android/i.test(userAgent)) {
      history.push({
        pathname: `/status`,
        search: window.location.search,
      });
    } else if (connected) {
      history.push({
        pathname: `/status`,
        search: window.location.search,
      });
    }
  }, [connected, wallet]);

  const content: React.ReactElement = (
    <div className="flex flex-col justify-center items-center full:items-start" data-testid="walletConnectionScreen">
      <>
        <img src="hero.png" alt="Civic Pass Logo" className="mb-4 w-[141px] h-[141px] full:hidden -top-16 absolute" />
        <div className="px-4 full:px-7 flex flex-col items-center mb-4 mt-16 full:mt-0">
          <PassSelector />
        </div>
        <div className="text-center full:text-left mb-5">
          <span className="items-center text-center pb-5">{t('screens.tokenStatus.description')}</span>
        </div>
        <div className="flex flex-col space-y-4 mb-5">
          <div className="flex flex-row mr-2">
            <p>
              <span className="whitespace-nowrap font-bold uppercase mr-2">{t('screens.steps.step1_title')}</span>
            </p>
            <p>{t('screens.steps.step1_description')}</p>
          </div>
          <div className="flex flex-row mr-2">
            <p>
              <span className="whitespace-nowrap font-bold uppercase mr-2">{t('screens.steps.step2_title')}</span>
            </p>
            <p>{t('screens.steps.step2_description')}</p>
          </div>
          <div className="flex flex-row mr-2">
            <p>
              <span className="whitespace-nowrap font-bold uppercase mr-2">{t('screens.steps.step3_title')}</span>
            </p>
            <p>{t('screens.steps.step3_description')}</p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div
            className="ml-1 full:ml-2 mb-3 text-secondary multi-connect-button"
            data-testid="multiChainConnectButton"
          >
            {showConnectButton && <MultichainConnectButton />}
          </div>
          <button
            type="button"
            className="bg-transparent disabled:text-textInactive textLink w-40"
            disabled={false}
            onClick={handleLearnMore}
          >
            <span>{t('screens.tokenStatus.supportLink', { context: status })}</span>
          </button>
        </div>
      </>
      <p className="mt-4 mb-5">{isUniquenessPortal() ? t('screens.tokenStatus.uniquenessBlurb') : ''}</p>
    </div>
  );

  return (
    <MainContainer>
      <div className="flex items-center justify-center">
        <Card
          ref={containerRef}
          paddingRight={`${state.paddingRight}`}
          marginRight={`${state.marginRight}`}
          imageSize={`${state.imageSize}`}
        >
          <CircleImage
            src="hero.png"
            alt="Civic Pass Logo"
            imageSize={`${state.imageSize}`}
            marginRight={`${state.marginRight}`}
          />
          <CardContent>{content}</CardContent>{' '}
        </Card>
      </div>
    </MainContainer>
  );
}
