import { Theme } from '@rainbow-me/rainbowkit';

// eslint-disable-next-line import/prefer-default-export
export const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: '...',
  },
  colors: {
    accentColor: 'rgb(255, 107, 78)',
    accentColorForeground: 'rgb(0, 0, 0)',
    actionButtonBorder: '...',
    actionButtonBorderMobile: '...',
    actionButtonSecondaryBackground: 'rgba(255, 107, 78, .2)',
    closeButton: 'rgb(255, 107, 78)',
    closeButtonBackground: 'rgba(255, 107, 78, .2)',
    connectButtonBackground: 'rgb(255, 107, 78)',
    connectButtonBackgroundError: '...',
    connectButtonInnerBackground: 'rgb(255, 107, 78)',
    connectButtonText: 'rgb(255,255,255)',
    connectButtonTextError: '...',
    connectionIndicator: '...',
    downloadBottomCardBackground: '...',
    downloadTopCardBackground: '...',
    error: '...',
    generalBorder: '...',
    generalBorderDim: '...',
    menuItemBackground: 'rgba(255, 107, 78, .2)',
    modalBackdrop: 'rgba(255, 107, 78, .2)',
    modalBackground: 'rgb(255, 255, 255)',
    modalBorder: 'rgba(0, 0, 0, 0)',
    modalText: 'rgb(0, 0, 0)',
    modalTextDim: 'rgb(0, 0, 0)',
    modalTextSecondary: 'rgb(0, 0, 0)',
    profileAction: 'rgb(255, 107, 78)',
    profileActionHover: 'rgba(206, 78, 52, 1)',
    profileForeground: '...',
    selectedOptionBorder: 'rgba(0, 0, 0, 0)',
    standby: '...',
  },
  fonts: {
    body: '...',
  },
  radii: {
    actionButton: '25px',
    connectButton: '25px',
    menuButton: '25px',
    modal: '25px',
    modalMobile: '25px',
  },
  shadows: {
    connectButton: '...',
    dialog: '...',
    profileDetailsAction: '...',
    selectedOption: '...',
    selectedWallet: '...',
    walletLogo: '...',
  },
};
