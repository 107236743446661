/* eslint-disable react/react-in-jsx-scope */
import { GatewayProvider as EvmGatewayProvider } from '@civic/ethereum-gateway-react';
import { GatewayProvider as SolanaGatewayProvider } from '@civic/solana-gateway-react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { BaseChain, SupportedChains, useChain, useWallet } from '@civic/multichain-connect-react-core';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { PolypassWithRemoteWallet } from '@civic/polypass-react';
import { Chain as SolanaChain } from '@civic/multichain-connect-react-solana-wallet-adapter';
import MainContainer from '../common/MainContainer/MainContainer';
import { usePassProvider } from '../providers/PassProvider';
import { useQueryParams } from '../common/hooks/useQueryParams';
import { solanaDevnetEndpoint } from '../common/config/solana';
import { useMultiWallet } from '../common/hooks/useMultiWallet';
import EthereumTokenStatusPanel from '../common/TokenStatus/EthereumTokenStatusPanel';
import SolanaTokenStatusPanel from '../common/TokenStatus/SolanaTokenStatusPanel';
import TokenStatusPanel from '../common/TokenStatus/TokenStatusPanel';

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export default function TokenStatusScreen(): JSX.Element {
  const { passConfig: config } = usePassProvider();
  const { wallet, chain: connectedChain, connected, disconnect } = useWallet();
  const { solanaWallet, evmGatewayWallet } = useMultiWallet();
  const { selectedChain } = useChain<SupportedChains.Solana, SolanaChain & BaseChain, never>();
  const [didConnect, setDidConnect] = useState<boolean>(false);
  const history = useHistory();
  const { updateChain, chainsParam } = useQueryParams();
  const { userAgent } = navigator;

  const network = useMemo(() => {
    return selectedChain?.type === SupportedChains.Solana && selectedChain?.rpcEndpoint === solanaDevnetEndpoint
      ? 'devnet'
      : 'mainnet-beta';
  }, [selectedChain]);

  useEffect(() => {
    if (/android/i.test(userAgent)) {
      history.push({
        pathname: `/status`,
        search: window.location.search,
      });
    }
  }, [connected, wallet]);

  useEffect(() => {
    if (wallet) {
      setDidConnect(true);
    } else if (!wallet && didConnect) {
      setDidConnect(false);
      updateChain('');
      history.push({
        pathname: '/',
        search: window.location.search,
      });
    }
  }, [didConnect, wallet]);

  useEffect(() => {
    if (!selectedChain) return;
    const formattedChainName = capitalize(selectedChain.name);
    document.title = `Civic Pass Portal: ${formattedChainName}`;
    updateChain(selectedChain.name.toLowerCase());
  }, [selectedChain]);

  useEffect(() => {
    if (selectedChain && chainsParam.length > 0 && !chainsParam.includes(selectedChain.name.toLowerCase())) {
      disconnect?.();
      history.push({
        pathname: '/',
        search: window.location.search,
      });
    }
  }, [selectedChain]);

  return (
    <MainContainer>
      <>
        {config && (
          <PolypassWithRemoteWallet
            iframeBaseUrl={config?.polypassIframeUrl}
            gatekeeperNetwork={config.gatekeeperNetworkPublicKey}
          >
            <>
              {config && connectedChain === SupportedChains.Ethereum && (
                <EvmGatewayProvider
                  options={{ autoShowModal: true }}
                  wallet={evmGatewayWallet}
                  stage={config.gatewayStage}
                  gatekeeperNetwork={config.gatekeeperNetworkPublicKey}
                  gatekeeperSendsTransaction={!config.useClientSends}
                >
                  <div className="main-panel" data-testid="evmMainPanel">
                    <EthereumTokenStatusPanel connected={connected} />
                  </div>
                </EvmGatewayProvider>
              )}
            </>
            <>
              {config && selectedChain?.type === SupportedChains.Solana && (
                <SolanaGatewayProvider
                  options={{ autoShowModal: true }}
                  wallet={solanaWallet}
                  cluster={network}
                  connection={new Connection(selectedChain.rpcEndpoint, 'processed')}
                  stage={config.gatewayStage}
                  gatekeeperNetwork={new PublicKey(config.gatekeeperNetworkPublicKey)}
                  gatekeeperSendsTransaction={!config.useClientSends}
                >
                  <div className="main-panel" data-testid="solanaMainPanel">
                    <SolanaTokenStatusPanel connected={connected} />
                  </div>
                </SolanaGatewayProvider>
              )}
              {!wallet && !selectedChain?.type && (
                <div className="main-panel">
                  <TokenStatusPanel connected={connected} />
                </div>
              )}
            </>
          </PolypassWithRemoteWallet>
        )}
      </>
    </MainContainer>
  );
}
